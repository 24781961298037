import Api from "../services/Api";

// Method to get current List of Notification Jobs
export const getNotificationJob = async (id, year, week, type, supplierId, productId) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (type) params.append('type', type);
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);

    console.log('getNotification params:', params.toString());

    const response = await Api.get("/notification/job", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to create Notification Job
export const createNotificationJob = async (year, week, type, supplierId, supplierLinkId, buyer, productId, purchaseId, purchaseHistId) => {
  console.log('createNotificationJob:', year, week, type, supplierId, productId, supplierLinkId, buyer, productId, purchaseId, purchaseHistId );
  let data = {weekId: (100 * year + week),
              year: year,
              week: week,
              type: type,
              supplierId: supplierId,
              supplierLinkId: supplierLinkId,
              buyer: buyer,
              productId: productId,
              purchaseId: purchaseId,
              purchaseHistId: purchaseHistId
            };
  try {
    const response = await Api.post("/notification/job", data);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};


